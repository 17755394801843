import React, { useState, useEffect } from "react";
import config from "../../config";

import Layout from "../components/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SocialLinks from "../components/SocialLinks";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faEnvelope,
  faMapMarker,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";

library.add(...[faWhatsapp, faCheck, faPhoneAlt, faEnvelope, faMapMarker]);

const ContactUs = ({ data, ...props }) => {
  const [success, setSuccess] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [values, setValues] = useState({});
  const [message, setMessage] = useState({});
  useEffect(() => {
    message.variant &&
      setTimeout(() => {
        setMessage({});
      }, 10000);
  }, [message]);
  const encode = (vals) => {
    return Object.keys(vals)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(vals[key])
      )
      .join("&");
  };
  const submitForm = (e) => {
    setClicked(true);
    if (values.email) {
      return;
    }
    e.preventDefault();
    
    fetch(`${config.url}${config.pathPrefix}.netlify/functions/eResponse/`, {
			method: "POST",
			body: JSON.stringify({ ...values, from: "message" }),
		})
			.then((data) => {
				setSuccess(true);
				setClicked(false);
				setMessage({
					msg: `A sua mensagem foi enviada com sucesso.`,
					variant: "success",
				});
			})
			.catch((i) => {
				// unnecessary because Mailchimp only ever
				// returns a 200 status code
				// see below for how to handle errors
				setMessage({
					msg: `opss, isto não era o que estavamos à espera... mas este tipo de coisas às vezes acontece :) `,
					variant: "error",
				});
			});
  };
  return (
    <Layout
      title={"Diga-nos olá..."}
      description="Temos todo o prazer em ajudar no que precisar :)"
    >
      <Header
        title="Diga-nos olá..."
        smallSubtitle="Temos todo o prazer em ajudar no que precisar :)"
        className="contact"
        active="contactos"
      />

      <div className="contact-section contacts-page">
        <main id="main">
          <div className="site-section">
            <div className="container-fluid">
              <div className="row">
                <Fade bottom>
                  <div className="col-sm-12 col-md-6 contacts">
                    <ul className="list-unstyled text-center">
                      <li className="">
                        <div className="contact-title">
                          <FontAwesomeIcon icon="map-marker" className="icon" />
                          {" Onde Estamos"}
                        </div>
                        <a
                          href="https://g.page/EMMIM?share"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Av. Miguel Bombarda, 1 - 3º Esq, <br />
                          1000-207 Lisboa
                        </a>
                      </li>
                      <li className="">
                        <div className="contact-title">
                          <FontAwesomeIcon icon="phone-alt" className="icon" />
                          {" Vamos Falar?"}
                        </div>
                        <a href="tel:+351211628072">+351 211 628 072</a>
                      </li>
                      <li className="">
                        <div className="contact-title">
                          <FontAwesomeIcon
                            icon={["fab", "whatsapp"]}
                            className="icon"
                          />
                          {"Whatsapp"}
                        </div>
                        <a href="https://wa.me/351910187357">
                          +351 910 187 357
                        </a>
                      </li>
                      <li className="">
                        <div className="contact-title">
                          <FontAwesomeIcon icon="envelope" className="icon" />
                          {" Dúvidas Gerais"}
                        </div>
                        <a href="mailto:geral@emmim.pt">geral@emmim.pt</a>
                      </li>
                      <li className="mb-3">
                        <SocialLinks sectionP={false} />
                      </li>
                    </ul>
                  </div>
                </Fade>
                <Fade right>
                  <div className="col-sm-12 col-md-6 text-center pt-5">
                    <h3>Envie-nos uma mensagem</h3>
                    <form
                      netlify
                      className="contact-form"
                      onSubmit={(e) => submitForm(e)}
                    >
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label className="text-black" for="name">
                            Nome
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            required="required"
                            onChange={(e) =>
                              setValues({
                                ...values,
                                [e.currentTarget.name]: e.currentTarget.value,
                              })
                            }
                            placeholder="Como se chama?"
                          />
                          <div className="validate"></div>
                        </div>
                        <div className="col-md-12 form-group">
                          <label className="text-black" for="cEmail">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            onChange={(e) =>
                              setValues({
                                ...values,
                                [e.currentTarget.name]: e.currentTarget.value,
                              })
                            }
                            style={{ display: "none" }}
                          />
                          <input
                            type="email"
                            name="cEmail"
                            required="required"
                            id="cEmail"
                            onChange={(e) =>
                              setValues({
                                ...values,
                                [e.currentTarget.name]: e.currentTarget.value,
                              })
                            }
                            data-rule="cEmail"
                            placeholder="Qual o seu e-mail?"
                          />
                          <div className="validate"></div>
                        </div>
                        <div className="col-md-12 form-group">
                          <label className="text-black" for="phoneNr">
                            Telefone (para ser contactado telefonicamente)
                          </label>
                          <input
                            type="tel"
                            name="phoneNr"
                            id="phoneNr"
                            onChange={(e) =>
                              setValues({
                                ...values,
                                [e.currentTarget.name]: e.currentTarget.value,
                              })
                            }
                            data-rule="phone"
                            placeholder="Deixe-nos o seu número de telefone"
                          />
                          <div className="validate"></div>
                        </div>
                        <div className="col-md-12 form-group">
                          <label className="text-black" for="message">
                            Mensagem
                          </label>
                          <textarea
                            name="message"
                            cols="30"
                            rows="5"
                            required="required"
                            onChange={(e) =>
                              setValues({
                                ...values,
                                [e.currentTarget.name]: e.currentTarget.value,
                              })
                            }
                            placeholder="Diga-nos algo :)"
                          ></textarea>
                          <div className="validate"></div>
                        </div>

                        <div className="col-md-12 text-left">
                          <label for="accept-terms" className="text-black">
                            <input
                              required
                              type="checkbox"
                              name="accept-terms"
                              id="accept-terms"
                              className="mr-3"
                            />
                            Li e aceito a vossa{" "}
                            <a
                              href="https://www.emmim.pt/privacidade"
                              target="_blank"
                            >
                              politica de privacidade
                            </a>
                          </label>
                        </div>
                        <div className="col-md-12 form-group">
                          <button
                            type="submit"
                            disabled={success}
                            className={`btn btn-cta pad`}
                          >
                            {clicked ? (
                              <>
                                <span className="lds-dual-ring" /> O carteiro
                                está a chegar
                              </>
                            ) : success ? (
                              <>
                                <FontAwesomeIcon
                                  icon="check"
                                  className="white"
                                />{" "}
                                Mensagem Enviada
                              </>
                            ) : (
                              <>Enviar</>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </main>
        <div className={`snackbar ${message.variant ? message.variant : ""}`}>
          {message.msg}
        </div>
      </div>

      <SocialLinks />
      <Footer />
    </Layout>
  );
};

export default ContactUs;
